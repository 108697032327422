import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form } from "formik"
import classNames from "classnames"

import FormInput from "elements/Form/FormInput"
import FormTextArea from "../../Elements/Form/FormTextarea"
import FormSelect from "../../Elements/Form/FormSelect"
import Captcha from "elements/Captcha"

import subjects from "../utils/emailSubjects.json"
import {
  contactUsValidationSchema,
  contactUsInitialValues,
} from "../utils/formData"
import { sendInquiry } from "../services/inquiry"

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/contactUs__complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const mailSparkle = data.complete.childImageSharp.fluid
  const [isCaptchaNotSolved, setIsCaptchaNotSolved] = useState(true)
  const [loading, setLoading] = useState(false)
  const [emailSubjects, setEmailSubjects] = useState([])
  const [notification, setNotification] = useState({
    message: "",
    active: false,
  })

  useEffect(() => {
    setEmailSubjects(subjects.map((item) => ({ value: item, label: item })))
  }, [])

  useEffect(() => {
    if (notification.active)
      setTimeout(() => {
        closeNotification()
      }, 5000)
  }, [notification])

  const closeNotification = () => {
    setNotification({ message: "", active: false, type: "" })
  }

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    sendInquiry(
      values,
      () => {
        resetForm()
        setLoading(false)
        setNotification({
          active: true,
          type: "success",
          message:
            "Thank you for your inquiry. We'll get back to you within the next business day.",
        })
      },
      () => {
        setLoading(false)
        setNotification({
          active: true,
          type: "danger",
          message:
            "Something went wrong with sending your email. Please try again.",
        })
      }
    )
  }

  return (
    <div>
      <section id="contact-us">
        <h2>Connect with us</h2>
        <hr />
        <div className="message">
          <div className="message-body">
            If you have questions or are experiencing problems not found in our{" "}
            <Link
              to="/help-center"
              className="has-text-weight-bold has-text-primary"
            >
              Help Center
            </Link>
            , let us know by filling out the form below.
          </div>
        </div>

        <div className="columns is-vcentered">
          <div className="column is-8">
            <Formik
              initialValues={contactUsInitialValues}
              onSubmit={handleSubmit}
              validationSchema={contactUsValidationSchema}
            >
              {({ values }) => (
                <Form autoComplete="off" method="post">
                  <FormInput
                    name="fullName"
                    placeholder="Juana Dela Cruz Jr."
                    value={values.fullName}
                    label="Full Name"
                    isRequired
                  />
                  <FormInput
                    name="email"
                    placeholder="jdelacruz@mail.com"
                    value={values.email}
                    label="Email"
                    isRequired
                  />
                  <FormSelect
                    name="subject"
                    placeholder="Select or type subject"
                    options={emailSubjects}
                    value={values.subject}
                    label="Type of Inquiry"
                    isRequired
                    isSelectable
                  />
                  <FormTextArea
                    name="emailBody"
                    value={values.emailBody}
                    label="Message"
                    isRequired
                  />
                  {notification.active && (
                    <div className={`notification is-${notification.type}`}>
                      <button
                        className="delete"
                        type="button"
                        onClick={closeNotification}
                      />
                      {notification.message}
                    </div>
                  )}
                  <div className={classNames("mt-3 mb-2")}>
                    <Captcha setIsCaptchaNotSolved={setIsCaptchaNotSolved} />
                  </div>
                  <button
                    type="submit"
                    className={classNames(
                      "button is-fullwidth is-primary has-text-centered",
                      {
                        "is-loading": loading,
                      }
                    )}
                    disabled={loading || isCaptchaNotSolved}
                  >
                    Send
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="column has-text-centered is-hidden-mobile">
            <Img fluid={mailSparkle} alt="mailImage" />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
